<script>
import FormView from '@/components/FormView'
import FormSelect from '@/components/FormView/FormSelect'
import { Input, InputNumber, TimePicker } from 'ant-design-vue'
import moment from 'moment'
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: this.form || { data: [] },
    }
  },
  methods: {
    toArray(data) {
      if (Array.isArray(data)) {
        return data
      } else {
        return data.split(',')
      }
    },
    sumbit() {
      this.$emit(
        'success',
        this.form.data.map((e) => {
          return {
            ...e,
            startTime: moment(this.getMomentValue(e.startTime)).format('HH:mm'),
            endTime: moment(this.getMomentValue(e.endTime)).format('HH:mm'),
            cycle: this.toArray(e.cycle).toString(),
            cycleName: this.toArray(e.cycle)
              .map((el) => {
                const name = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'][parseInt(el) - 1]
                return name
              })
              .toString(),
          }
        })
      )
    },
    getMomentValue(time) {
      var timeString = time
      var duration = moment.duration(timeString)
      var utcTime = moment.utc(duration.asMilliseconds())
      return utcTime
    },
    getForm() {
      const that = this
      return [
        {
          name: '景点名称',
          type: 'text',
          key: 'scenicName',
        },
        {
          name: '票型名称',
          type: 'text',
          key: 'thirdTypeName',
        },
        {
          type: 'table',
          name: '选择场次',
          labelCol: 16,
          wrapperCol: 8,
          cols: 24,
          showFootButton: true,
          dataSource: this.form.data,
          onAddData: () => {
            return {
              cycle: [],
              cycleName: [],
              endTime: moment(),
              name: '',
              peopleLimit: 1,
              remark: '',
              startTime: moment(),
            }
          },
          columns: [
            {
              dataIndex: 'name',
              title: '场次名称',
              width: 125,
              customRender: function (text, records, index, h) {
                return (
                  <Input
                    value={text}
                    onChange={(data) => {
                      records.name = data.target.value
                      that.$forceUpdate()
                    }}
                    placeholder="请输入场次名称"
                  />
                )
              },
            },
            {
              dataIndex: 'name',
              title: '时间范围',
              width: 200,
              customRender: function (text, records, index, h) {
                const data = [
                  {
                    name: '开始时间',
                    value: records.startTime || moment(),
                    onChange: (value) => {
                      records.startTime = value
                      that.$forceUpdate()
                    },
                  },
                  {
                    name: '结束时间',
                    value: records.endTime || moment(),
                    onChange: (value) => {
                      records.endTime = value
                      that.$forceUpdate()
                    },
                  },
                ]
                return (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {data.map((e, i) => {
                      return (
                        <TimePicker
                          format="HH:mm"
                          style={{ width: '100%', marginTop: i > 0 ? '10px' : '0px' }}
                          value={moment(that.getMomentValue(e.value))}
                          placeholder={'请选择' + e.name}
                          onChange={(value) => {
                            e.onChange(value)
                          }}
                        />
                      )
                    })}
                  </div>
                )
              },
            },
            {
              dataIndex: 'peopleLimit',
              title: '人数限制',
              width: 120,
              align: 'center',
              customRender: function (text, records, index, h) {
                return (
                  <InputNumber
                    value={text}
                    onChange={(data) => {
                      records.peopleLimit = data
                      that.$forceUpdate()
                    }}
                  />
                )
              },
            },
            {
              dataIndex: 'cycle',
              title: '场次周期',
              width: 180,
              customRender: function (text, records, index, h) {
                return (
                  <FormSelect
                    item={{ name: '场次周期', props: { mode: 'multiple' } }}
                    value={text}
                    typeData={[
                      { name: '周一', value: '1' },
                      { name: '周二', value: '2' },
                      { name: '周三', value: '3' },
                      { name: '周四', value: '4' },
                      { name: '周五', value: '5' },
                      { name: '周六', value: '6' },
                      { name: '周日', value: '7' },
                    ]}
                    onChange={(value) => {
                      records.cycle = value
                      that.$forceUpdate()
                    }}
                  />
                )
              },
            },
            {
              dataIndex: 'remark',
              title: '备注',
              customRender: function (text, records, index, h) {
                return (
                  <Input
                    value={text}
                    onChange={(data) => {
                      records.remark = data.target.value
                      that.$forceUpdate()
                    }}
                  />
                )
              },
            },
            {
              title: '操作',
              type: 'buttonGroup',
              align: 'center',
              typeData: ({ records, index }) => {
                return [
                  {
                    name: '删除',
                    popTitle: '删除',
                    type: 'pop',
                    display: true,
                    onClick: () => {
                      this.form.data.splice(index, 1)
                      this.$forceUpdate()
                    },
                  },
                ]
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return <FormView layout={'horizontal'} data={this.getForm()} form={this.form} />
  },
}
</script>