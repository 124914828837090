<script>
import api from '@/command/api'
import moment from 'moment'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'
import { Message, TimePicker, Dropdown, Row, Col } from 'ant-design-vue'
import { thirdTypeDataList } from '@/utils/textFile'
import FormRangePicker from '@/components/FormView/FormRangePicker'
import sessionModal from '@/Modal/session'
export default {
  name: 'scenicAreasTicket',
  data() {
    return {
      ...api.command.getState(),
      selectedArr: [],
      selectedArr2: [],
      roundData: {
        farmRoundList: [],
      },
    }
  },
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      const { id } = this.$route.query
      api.command.getList.call(this, {
        url: '/goods/farmTicket/page',
        paramsValue: {
          id: id,
        },
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '门票名称',
          key: 'productName',
          type: 'input',
        },
        {
          name: '票种',
          key: 'thirdTypeName',
          type: 'select',
          typeData: thirdTypeDataList().map((e) => {
            return {
              ...e,
              value: e.name,
            }
          }),
        },
        {
          label: '',
          type: 'text',
        },
        // {
        //   name: '店铺名称',
        //   type: 'input',
        //   key: 'mainBodyName'
        // }
      ]
    },
    openModal(form, records) {
      apiTool.showModal({
        title: '场次',
        width: 1200,
        viewProps: {
          layout: 'horizontal',
          form: form,
        },
        view: sessionModal,
        success: ({ data, setHidden }) => {
          postAction('/farmRound/saveBatch', { productId: records.id, roundList: data }, '/api').then(() => {
            this.$message.success('提交成功')
            setHidden()
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'productName',
          title: '门票名称',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-order-item">
                <img src={records.bannerUrl} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.productName.length - b.productName.length,
        },
        // {
        //   dataIndex: 'linkShopName',
        //   title: '店铺名称',
        //   align: 'left',
        //   sorter: (a, b) => a.linkShopName.length - b.linkShopName.length
        // },
        {
          dataIndex: 'comboFlag',
          title: '套餐',
          customRender: (text) => {
            return text == '1' ? '是' : '否'
          },
        },
        // {
        //   dataIndex: 'thirdTypeName',
        //   title: '票种',
        //   align: 'left',
        //   // customRender: (text) => (thirdTypeDataList()[text] || { text: '' }).text,
        // },
        {
          dataIndex: 'readCount',
          title: '访问量',
          align: 'left',
          sorter: (a, b) => a.readCount - b.readCount,
        },
        {
          dataIndex: 'saleCount',
          title: '销量',
          align: 'left',
          sorter: (a, b) => a.saleCount - b.saleCount,
        },
        {
          dataIndex: 'hxCount',
          title: '核销量',
          align: 'left',
          sorter: (a, b) => a.hxCount - b.hxCount,
        },
        {
          dataIndex: 'allStock',
          title: '库存',
          align: 'left',
          sorter: (a, b) => a.allStock - b.allStock,
        },
        {
          dataIndex: 'unUsedStock',
          title: '剩余库存',
          align: 'left',
          sorter: (a, b) => a.unUsedStock - b.unUsedStock,
        },
        {
          dataIndex: 'useStartTime',
          title: '有效期',
          align: 'left',
          isId: true,
          sorter: (a, b) => moment(a.hxStartTime).unix() - moment(b.hxStartTime).unix(),
          customRender: function (text, records) {
            return records.hxExpireType == '1' ? (
              `必须在购买后${records.hxAfterDay}天内使用`
            ) : records.hxStartTime == null ? (
              ''
            ) : (
              <div>
                {moment(records.hxStartTime).format('YYYY/MM/DD') +
                  '~' +
                  moment(records.hxEndTime).format('YYYY/MM/DD')}
              </div>
            )
          },
          onExport: (text, records) => {
            return records.hxExpireType == '1' ? (
              `必须在购买后${records.hxAfterDay}天内使用`
            ) : records.hxStartTime == null ? (
              ''
            ) : (
              <div>
                {moment(records.hxStartTime).format('YYYY/MM/DD') +
                  '~' +
                  moment(records.hxEndTime).format('YYYY/MM/DD')}
              </div>
            )
          },
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          typeData: ({ records }) => {
            const { id } = this.$route.query
            return [
              {
                display: records.upDown == '0',
                name: records.showState == '0' ? '取消隐藏' : '隐藏',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/goods/farmTicket/${records.showState == 0 ? 'show' : 'hide'}?id=${records.id}`,
                  })
                },
              },
              {
                display: true,
                name: records.upDown == '0' ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/goods/farmTicket/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                display:records.thirdTicket == 1,
                name: '同步价格',
                type: 'pop',
                popTitle: '确认同步价格吗?',
                onClick: () =>{
                   api.command.syncGET.call(this, {
                    url: `/goods/farmTicketPriceConfig/syncProductPrice?productId=${records.id}`,
                   })
                }      
              },
              {
                display: records.hxExpireType == '0',
                name: '场次',
                onClick: () => {
                  let form = {}
                  getAction('/api/farmRound/list', { productId: records.id }).then((e) => {
                    if (e.data) {
                      form = records
                      form.data = e.data
                      this.openModal(form, records)
                    } else {
                      form = records
                      form['data'] = []
                      this.openModal(form, records)
                    }
                  })
                },
              },
              {
                display: true,
                name: '日历价格',
                onClick: () =>{
                  if (records.comboFlag == 1){
                    this.$router.push(
                               `/commodityManagement/panelCalendar?productId=${records.productComboId}&productType=3&productName=${records.productComboName}`
                            )
                  }else {
                    this.$router.push(
                               `/commodityManagement/calendar?productId=${records.id}&productType=1&thirdTicket=${records.thirdTicket}&free=true`
                            )
                  }
                            
                }
              },
              // {
              //   display: records.thirdTicket == '0',
              //   name: '场次',
              //   onClick: () => {
              //     this.rowSessionClick(records)
              //   },
              // },
              {
                display: true,
                name: '编辑',
                onClick: () => {
                  const { id, shopId } = this.$route.query
                  this.$router.push(
                    `/commodityManagement/scenicAreasTicketDetail?scenicId=${id}&shopId=${shopId}&id=${records.id}`
                  )
                },
              },
              {
                display: records.upDown != 0,
                name: '删除',
                type: 'pop',
                popTitle: '是否确认删除?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/goods/farmTicket?id=${records.id}`,
                  }),
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getDrawerForm(data) {
      return [
        {
          form: [
            {
              name: '景点名称',
              type: 'text',
              key: 'scenicName',
              labelCol: { span: 3 },
              wrapperCol: { span: 21 },
            },
            {
              name: '票型名称',
              type: 'text',
              key: 'ticketName',
              labelCol: { span: 3 },
              wrapperCol: { span: 21 },
            },
            {
              name: '选择场次',
              type: 'table',
              labelCol: { span: 3 },
              wrapperCol: { span: 24 },
              dataSource: this.roundData.farmRoundList,
              showFootButton: false,
              showRowSelect: true,
              showPagination: false,
              bordered: true,
              rowKey: 'id',
              selectedRowKeys: this.selectedArr,
              selectedRows: this.selectedArr2,
              rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                  this.selectedArr = selectedRowKeys
                  this.selectedArr2 = selectedRows
                },
              },
              columns: [
                {
                  dataIndex: 'name',
                  align: 'left',
                  title: '场次名称',
                },
                {
                  dataIndex: 'startTime',
                  align: 'left',
                  title: '时间范围',
                  customRender: function (text, records) {
                    return records.startTime == null ? '' : <div>{records.startTime + '~' + records.endTime}</div>
                  },
                },
                {
                  dataIndex: 'peopleLimit',
                  align: 'left',
                  title: '人数限制',
                },
                {
                  dataIndex: 'cycleName',
                  align: 'left',
                  title: '场次周期',
                },
                {
                  dataIndex: 'remark',
                  align: 'left',
                  title: '备注',
                },
              ],
            },
          ],
        },
      ]
    },
    rowSessionClick(row) {
      this.selectedArr = []
      this.selectedArr2 = []
      getAction(`/goods/farmTicket/queryRound?id=${row.id}`).then((res) => {
        this.roundData = res.data
        if (this.roundData.farmRoundList && this.roundData.farmRoundList.length > 0) {
          this.selectedArr2 = this.roundData.farmRoundList.filter((e) => e.light == 1)
          this.selectedArr = this.roundData.farmRoundList.filter((e) => e.light == 1).map((obj) => obj.id)
        }
        this.showRoundDrawer()
      })
    },
    showRoundDrawer() {
      apiTool.showDrawer({
        title: '场次',
        width: '750px',
        view: DrawerForm,
        viewProps: {
          data: (data) => this.getDrawerForm(data),
          form: {
            ...this.roundData,
          },
        },
        success: ({ data, setHidden }) => {
          // if (this.selectedArr.length == 0) {
          //   Message.error('请先选择场次!')
          //   return
          // }
          postAction('/goods/farmTicket/bindRound', {
            id: data.productId,
            roundIds: this.selectedArr,
          }).then((res) => {
            Message.success('操作成功')
            setHidden()
            this.getInit()
          })
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    getButton() {
      const { id, shopId } = this.$route.query
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () =>
            this.$router.push(`/commodityManagement/scenicAreasTicketDetail?scenicId=${id}&shopId=${shopId}`),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.del.call(this, {
                  url: '/goods/farmTicket/deleteBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmTicket/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/goods/farmTicket/downBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
        // {
        //   name: '页面设置',
        //   type: 'primary',
        //   ghost: true,
        //   onClick: () => this.$router.push('/commodityManagement/scenicAreasPageSetDetail')
        // }
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 15px;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
</style>
